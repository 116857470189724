import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  ENMS_HEALTH,
  ENMS_SPECIAL,
  ENMS_QUALIFICATION,
  ENMS_SKILL,
  BOOKING,
  PAYMENT,
  USERS,
  STATIC,
  TRANSACTION,
  PAYOUT,
  DASHBOARD,
  FIELDS,
  LEVEL_TWO_PROVIDERS,
  CHRONIC,
  ENMS_CHIROPOCTOR,
  ENMS_THERAPY,
  ENMS_LIFESTYLE,
  ENMS_CHIROPOCTOR_ADDONS,
} from "../../halpers/url_helper";
import { USERS_PROVIDER } from "../../halpers/url_helper";

// const baseUrl = "https://akandoc-backend.alcax.com";
const baseUrl = process.env.REACT_APP_BASE_URL;
// const baseUrl = "http://localhost:3040";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", token ? `Bearer ${token}` : "");
        // headers.set("Content-type", "application/json");
        return headers;
      }
    },
  }),
  tagTypes: [
    "get-count",
    "providers",
    "health",
    "specialization",
    "qualification",
    "skill",
    "chiropractor",
    "chiropractoraddons",
    "therapy",
    "chronic",
    "booking",
    "payments",
    "users",
    "static-pages",
    "transections",
    "payout",
  ],
  endpoints: (builder) => ({
    //dashboard
    getDashboard: builder.query({
      query: () => `${DASHBOARD}`,
      providesTags: ["get-count"],
    }),

    //health
    getHealth: builder.query({
      query: () => `${ENMS_HEALTH}`,
      providesTags: ["health"],
    }),

    postHealth: builder.mutation({
      query: (data) => ({
        url: `${ENMS_HEALTH}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["health"],
    }),

    putHealth: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_HEALTH}/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["health"],
    }),

    delHealth: builder.mutation({
      query: (id) => ({
        url: `${ENMS_HEALTH}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["health"],
    }),

    //qualification
    getQualification: builder.query({
      query: () => `${ENMS_QUALIFICATION}`,
      providesTags: ["qualification"],
    }),

    postQualification: builder.mutation({
      query: (data) => ({
        url: `${ENMS_QUALIFICATION}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["qualification"],
    }),

    putQualification: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_QUALIFICATION}/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["qualification"],
    }),

    delQualification: builder.mutation({
      query: (id) => ({
        url: `${ENMS_QUALIFICATION}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["qualification"],
    }),

    //specialization
    getSpecial: builder.query({
      query: () => `${ENMS_SPECIAL}`,
      providesTags: ["specialization"],
    }),

    postSpecial: builder.mutation({
      query: (data) => ({
        url: `${ENMS_SPECIAL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["specialization"],
    }),

    putSpecial: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_SPECIAL}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["specialization"],
    }),

    delSpecial: builder.mutation({
      query: (id) => ({
        url: `${ENMS_SPECIAL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["specialization"],
    }),

    //chronic

    getChronicDisease: builder.query({
      query: () => `${CHRONIC}`,
      providesTags: ["chronic"],
    }),
    createChronicDisease: builder.mutation({
      query: (data) => ({
        url: `${CHRONIC}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["chronic"],
    }),

    editChronicDisease: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${CHRONIC}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["chronic"],
    }),

    deleteChronicDisease: builder.mutation({
      query: (id) => ({
        url: `${CHRONIC}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["chronic"],
    }),

    //skill
    getSkill: builder.query({
      query: () => `${ENMS_SKILL}`,
      providesTags: ["skill"],
    }),

    postSkill: builder.mutation({
      query: (data) => ({
        url: `${ENMS_SKILL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["skill"],
    }),

    putSkill: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_SKILL}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["skill"],
    }),

    delSkill: builder.mutation({
      query: (id) => ({
        url: `${ENMS_SKILL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["skill"],
    }),

    // cHiropractor

    getChiropractor: builder.query({
      query: () => `${ENMS_CHIROPOCTOR}`,
      providesTags: ["chiropractor"],
    }),

    postChiropractor: builder.mutation({
      query: (data) => ({
        url: `${ENMS_CHIROPOCTOR}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["chiropractor"],
    }),

    putChiropractor: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_CHIROPOCTOR}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["chiropractor"],
    }),

    delChiropractor: builder.mutation({
      query: (id) => ({
        url: `${ENMS_CHIROPOCTOR}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["chiropractor"],
    }),
    
    // chiropractor addons

    getChiropractoraddons: builder.query({
      query: () => `${ENMS_CHIROPOCTOR_ADDONS}`,
      providesTags: ["chiropractoraddons"],
    }),

    postChiropractoraddons: builder.mutation({
      query: (data) => ({
        url: `${ENMS_CHIROPOCTOR_ADDONS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["chiropractoraddons"],
    }),

    putChiropractoraddons: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_CHIROPOCTOR_ADDONS}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["chiropractoraddons"],
    }),

    delChiropractoraddons: builder.mutation({
      query: (id) => ({
        url: `${ENMS_CHIROPOCTOR_ADDONS}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["chiropractoraddons"],
    }),

     // therapy

     getTherapy: builder.query({
      query: () => `${ENMS_THERAPY}`,
      providesTags: ["therapy"],
    }),

    postTherapy: builder.mutation({
      query: (data) => ({
        url: `${ENMS_THERAPY}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["therapy"],
    }),

    putTherapy: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_THERAPY}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["therapy"],
    }),

    delTherapy: builder.mutation({
      query: (id) => ({
        url: `${ENMS_THERAPY}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["therapy"],
    }),

     //lifestyle
     getLifestyle: builder.query({
      query: ({serviceType}) => `${ENMS_LIFESTYLE}?serviceType=${serviceType ? serviceType : 2}`,
      providesTags: ["lifestyle"],
    }),

    postLifestyle: builder.mutation({
      query: (data) => ({
        url: `${ENMS_LIFESTYLE}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["lifestyle"],
    }),

    putLifestyle: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${ENMS_LIFESTYLE}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["lifestyle"],
    }),

    delLifestyle: builder.mutation({
      query: (id) => ({
        url: `${ENMS_LIFESTYLE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["lifestyle"],
    }),

    //User Provider
    getUserProvider: builder.query({
      query: () => `${USERS_PROVIDER}`,
      providesTags: ["providers"],
    }),

    getLevelTwoProviders: builder.query({
      query: () => ({ url: LEVEL_TWO_PROVIDERS }),
    }),

    postUserProvider: builder.mutation({
      query: (data) => ({
        url: `${USERS_PROVIDER}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["providers"],
    }),

    postField: builder.mutation({
      query: (data) => ({
        url: `${FIELDS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["fields"],
    }),

    putUserProvider: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${USERS_PROVIDER}/${id}`,
        method: "PATCH",
        body: rest.type,
      }),
      invalidatesTags: ["providers"],
    }),

    delUserProvider: builder.mutation({
      query: (id) => ({
        url: `${USERS_PROVIDER}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["providers"],
    }),

    //User
    getUser: builder.query({
      query: () => `${USERS}`,
      providesTags: ["users"],
    }),
    getField: builder.query({
      query: () => `${FIELDS}`,
      providesTags: ["fields"],
    }),

    postUser: builder.mutation({
      query: (data) => ({
        url: `${USERS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["users"],
    }),

    putUser: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${USERS}/${id}`,
        method: "PATCH",
        body: rest.type,
      }),
      invalidatesTags: ["users"],
    }),
    //booking
    getBooking: builder.query({
      query: (id) => `${BOOKING}?status=${id}`,
      providesTags: ["booking"],
    }),
    //Payment
    getPayment: builder.query({
      query: (id) => `${PAYMENT}`,
      providesTags: ["payments"],
    }),

    putPayment: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${PAYMENT}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["payments"],
    }),

    //static Page
    getStatic: builder.query({
      query: () => `${STATIC}`,
      providesTags: ["static-pages"],
    }),

    postStatic: builder.mutation({
      query: (data) => ({
        url: `${STATIC}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["static-pages"],
    }),

    putStatic: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${STATIC}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["static-pages"],
    }),
    delStatic: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${STATIC}/${id}`,
        method: "DELETE",
        body: rest.type,
      }),
      invalidatesTags: ["static-pages"],
    }),
    //Transations
    getTransaction: builder.query({
      query: () => `${TRANSACTION}`,
      providesTags: ["transections"],
    }),
    //payout
    getPayout: builder.query({
      query: (id) => `${PAYOUT}`,
      providesTags: ["payout"],
    }),
    putPayout: builder.mutation({
      query: ({ id, ...rest }) => ({
        url: `${PAYOUT}/${id}`,
        method: "PUT",
        body: rest.type,
      }),
      invalidatesTags: ["payout"],
    }),
  }),
});

export const {
  //dashboard
  useGetDashboardQuery,
  //helath
  useGetHealthQuery,
  useDelHealthMutation,
  usePostHealthMutation,
  usePutHealthMutation,
  // qualification
  useGetQualificationQuery,
  useDelQualificationMutation,
  usePostQualificationMutation,
  usePutQualificationMutation,
  //special
  useGetSpecialQuery,
  useDelSpecialMutation,
  usePostSpecialMutation,
  usePutSpecialMutation,
  //skill
  useGetSkillQuery,
  useDelSkillMutation,
  usePostSkillMutation,
  usePutSkillMutation,
  // chiropractor
  useGetChiropractorQuery,
  useDelChiropractorMutation,
  usePostChiropractorMutation,
  usePutChiropractorMutation,
  // chiropractorAddOns
  useGetChiropractoraddonsQuery,
  useDelChiropractoraddonsMutation,
  usePostChiropractoraddonsMutation,
  usePutChiropractoraddonsMutation,

  // therapy
  useGetTherapyQuery,
  useDelTherapyMutation,
  usePostTherapyMutation,
  usePutTherapyMutation,

  // LIFESTYLE
  useGetLifestyleQuery,
  useDelLifestyleMutation,
  usePostLifestyleMutation,
  usePutLifestyleMutation,
  //user Provider
  useDelUserProviderMutation,
  useGetUserProviderQuery,
  useGetLevelTwoProvidersQuery,
  usePostUserProviderMutation,
  usePutUserProviderMutation,

  //level two provider
  useGetLevelTwoProviderQuery,

  //users
  useGetUserQuery,
  usePostUserMutation,
  usePutUserMutation,
  // booking
  useGetBookingQuery,
  // fields
  useGetFieldQuery,
  usePostFieldMutation,
  // Payment
  useGetPaymentQuery,
  usePutPaymentMutation,
  // static-page
  useDelStaticMutation,
  useGetStaticQuery,
  usePostStaticMutation,
  usePutStaticMutation,
  //transections
  useGetTransactionQuery,
  //payout
  useGetPayoutQuery,
  usePutPayoutMutation,

  //chronic
  useGetChronicDiseaseQuery,
  useCreateChronicDiseaseMutation,
  useEditChronicDiseaseMutation,
  useDeleteChronicDiseaseMutation,
} = adminApi;
