import { Modal } from "antd";

const ProviderBankDetailModal = ({ data, showModal, setShowModal }) => {
  return (
    <>
      <Modal
        title="Provider Bank Details"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={<></>}
      >
        <p>
          Bank Name: <b>{data?.bankName || "N/A"}</b>
        </p>
        <p>
          Accoutholder Name: <b>{data?.accountHolderName || "N/A"}</b>
        </p>
        <p>
          Accout Number: <b>{data?.accountNumber || "N/A"}</b>
        </p>
        <p>
          Code: <b>{data?.code || "N/A"}</b>
        </p>
        <p>
          Account Type: <b>{data?.accountType || "N/A"}</b>
        </p>
      </Modal>
    </>
  );
};

export default ProviderBankDetailModal;
