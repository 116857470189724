import React, { useState } from 'react';

import { Button, Image, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../component/MainLayout';

import { useGetUserProviderQuery } from '../../../redux/Api/adminApi';
import { useDispatch } from 'react-redux';
import { showEditModalOpen } from '../../../redux/slice/healthSlice';
import ProviderDetailModal from '../../../component/Modals/ProviderDetailModal';
import { IoMdEye } from 'react-icons/io';
import { AiFillEdit } from 'react-icons/ai';
import ProviderEditModal from './ProviderEditModal';

const ManageProvider = () => {
  const navigate = useNavigate();
  const { data } = useGetUserProviderQuery();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [providerData, setProviderData] = useState({});

  const columns = [
    {
      key: '1',
      title: 'Id',
      dataIndex: '_id',
    },
    {
      key: '2',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: '3',
      title: 'Email',
      dataIndex: 'email',
    },
    {
      key: '4',
      title: 'Image',
      dataIndex: 'imageUrl',
      render: (record) => (record ? <Image width={50} height={50} src={record} fallback='Uers-image' /> : 'No Image'),
    },
    {
      key: '5',
      title: 'Provider Level',
      dataIndex: 'level',
      render: (_, row) => {
        if (row.serviceType === 0 && row.level === 2) {
          return <span>Level 2</span>;
        }
        return <span>Level 1</span>;
      },
    },
    {
      key: '6',
      title: 'Action',
      render: (record) => {
        return (
          <>
            <AiFillEdit className='action-icon' onClick={() => handelEdit(record)} style={{ color: 'green' }} />
            <IoMdEye onClick={() => handleView(record)} className='action-icon' style={{ color: '#1677ff' }} />
          </>
        );
      },
    },
  ];

  const handleView = (record) => {
    setShowModal(true);
    setProviderData(record);
  };

  const handelEdit = (record) => {
    dispatch(showEditModalOpen(record));
    // navigate('/user/provider-edit-form');
  };

  return (
    <>
      <MainLayout>
        <ProviderDetailModal data={providerData} showModal={showModal} setShowModal={setShowModal} />
        <ProviderEditModal />
        <div className='table'>
          <Button onClick={() => navigate('/user/provider-add-form')} className='gred-button'>
            Add Provider
          </Button>
          <Table dataSource={data && data.data} columns={columns} scroll={{ y: 400, x: 1000 }} bordered />
        </div>
      </MainLayout>
    </>
  );
};

export default ManageProvider;
